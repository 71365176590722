<template>
	<div class="login-content">
		<p class="welcome-title">欢迎登录理光技术中心</p>
		<div class="login-box">
			<div class="login-box-left">
				<img class="login-logo" src="@/assets/images/logo2.png" alt="">
				<ul class="login-ul" @keyup.enter="handleLogin">
					<li class="login-li">
						<img class="left-icon" src="@/assets/images/login.png" alt="">
						<input type="text" v-model="account" placeholder="请输入用户ID/手机/邮箱号">
					</li>
					<li v-if="passFlag">
						<img class="left-icon" src="@/assets/images/password.png" alt="">
						<input type="password" v-model="password" placeholder="请输入密码">
						<img class="right-icon" src="@/assets/images/biyan.png" alt="" @click="passFlag = false">
					</li>
					<li v-if="!passFlag">
						<img class="left-icon" src="@/assets/images/password.png" alt="">
						<input type="text" v-model="password" placeholder="请输入密码">
						<img class="right-icon" src="@/assets/images/zhengyan.png" alt="" @click="passFlag = true">
					</li>
					<li class="lh-17">
						<el-checkbox v-model="checked"></el-checkbox>
						<span class="check-span" @click="handleCheck">我已阅读并同意《免责声明》</span>
					</li>
					<button class="submit-btn" @click="handleLogin">登录</button>
					<li style="height:20px;">
						<p class="forget-btn" @click="handleForget">忘记密码，通过邮箱找回</p>
						<!-- <p class="rigister-btn" @click="handleRegister">去注册></p> -->
					</li>
					<li class="enter-btn">
						<img class="point-icon" src="@/assets/images/point.png" alt="">
						<a target="_blank" href="https://www.ricoh.com.cn/" rel="noopener noreferrer">点击进入理光官网</a>
					</li>
				</ul>
				<div class="qr-box">
					<div class="qr-item">
						<img src="@/assets/images/liguangzhongguo.png" alt="">
						<p>理光(中国)微信号</p>
					</div>
					<div class="qr-item">
						<img src="@/assets/images/ricoh_wechat.png" alt="">
						<p>理光技术中心</p>
					</div>
					<div class="qr-item">
						<img src="@/assets/images/liguangfuwu.png" alt="">
						<p>理光服务微信号</p>
					</div>
				</div>
			</div>
			<img class="login-box-right" src="@/assets/images/beijing.png" alt="">
		</div>
		<p class="banquan">版权所有 © 2021理光（中国）投资有限公司</p>
		<p class="beian">备案编号：沪ICP备08111514号</p>
		<el-dialog
			:visible.sync="visible"
			:show-close="false"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			class="role-dialog"
			width="620px">
			<span slot="title" class="dialog-title">
				<span>免责声明</span>
				<!-- <img src="@/assets/images/close.png" alt="" @click="visible = false"> -->
			</span>
			<div class="dialog-box">
				理光（中国）投资有限公司尽可能做到网站内容完整、准确，但对其完整性、准确性不做任何担保，亦不暗示它的适用性。
				理光网站提供的所有信息均以原状呈现，对于所含的内容不做任何表述和担保。
				除非本网站上有特别的声明，作者、理光公司、理光公司主管、员工及其他人员，对于使用网站所造成的损害均不负责。
				这是一个全部的责任范围，包括补偿义务、直接或间接的伤害、延续的伤害、数据损失、利润收入损失、财产损失、及第三方索赔等等。
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button class="left-btn" @click="handleNoAgree">不同意</el-button>
				<el-button class="right-btn" @click="submit">同意并登录</el-button>
			</span>
		</el-dialog>
		<el-dialog
			:visible.sync="forgetVisible"
			:show-close="false"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			class="role-dialog"
			width="320px">
			<span slot="title" class="dialog-title">
				<span>忘记密码</span>
				<img src="@/assets/images/close.png" alt="" @click="forgetVisible = false">
			</span>
			<div class="forget-dialog-box">
				<p><span style="color:red;">*</span>邮箱:</p>
				<input class="forget-input" type="text" v-model="forget" placeholder="请输入邮箱">
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button class="forget-submit-btn" @click="handleForgetSubmit">确定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
import {debounce} from '@/utils/debounce'

export default {
	data() {
		return {
			visible: false,
			forgetVisible: false,
			account: '',
			password: '',
			forget: '',
			passFlag: true,
			checked: false,
		}
	},
	created() {
		this.account = window.localStorage.getItem('code') ? window.localStorage.getItem('code') : '';
		this.password = window.localStorage.getItem('password') ? window.localStorage.getItem('password') : '';
		this.checked = window.localStorage.getItem('checked') ? true : false;
	},
	methods: {
		handleLogin: debounce(function () {
			// 防止token失效弹出多个提示弹窗
			window.axiosCancel = false;

			if (this.account == '') {
				this.$message.error('请输入用户ID/手机/邮箱号');
				return
			}
			if (this.password == '') {
				this.$message.error('请输入密码');
				return
			}
			if (!this.checked) {
				this.$message.error('请阅读免责声明');
				s
				return
			}
			let that = this;
			that.$request.post(
				"Login",
				false,
				{
					code: that.account,
					pass: that.password
				},
				function (r) {
					window.localStorage.setItem('code', that.account);
					window.localStorage.setItem('password', that.password);
					window.localStorage.setItem('checked', '1');
					if (r.code == "0") {
						window.sessionStorage.setItem('token', r.data.token);
						window.sessionStorage.setItem('userInfo', JSON.stringify(r.data.userDetail.sysUser));
						window.sessionStorage.setItem('perms', JSON.stringify(r.data.userDetail.perms));
						// 登录密码为初始密码
						if (r.data.userDetail.initPassFlag == null || r.data.userDetail.initPassFlag == '') {
							window.sessionStorage.setItem('initPassFlag', '')
						} else {
							window.sessionStorage.setItem('initPassFlag', '1')
						}
						// 登录密码超过6个月未修改
						if (r.data.userDetail.passwordExpiry == null || r.data.userDetail.passwordExpiry == '') {
							window.sessionStorage.setItem('passwordExpiry', '')
						} else {
							window.sessionStorage.setItem('passwordExpiry', r.data.userDetail.passwordExpiry)
						}
						// 没有邮箱直接跳转编辑页
						if (r.data.userDetail.sysUser.email == '' || r.data.userDetail.sysUser.email == null) {
							that.$router.push({
								name: 'editUserEmail'
							})
							return
						}
						if (window.sessionStorage.getItem('mailId')) {
							// 直接跳转通信箱详情
							if (r.data.userDetail.perms.includes('subject')) {
								that.$router.push({
									name: 'mailboxDetail',
									query: {
										id: window.sessionStorage.getItem('mailId')
									}
								})
							} else {
								that.$message.warning('当前用户没有通信箱权限');
								that.$router.push({
									name: 'home'
								})
							}
							window.sessionStorage.setItem('mailId', '');
						} else if (window.sessionStorage.getItem('videoId')) {
							// 直接跳转视频详情
							if (r.data.userDetail.perms.includes('video:detail')) {
								that.$router.push({
									name: 'videoDetail',
									query: {
										id: window.sessionStorage.getItem('videoId')
									}
								})
							} else {
								that.$message.warning('你所在的代理商没有此权限或者你的区域代理没有此权限，通过通信箱向渠道支援咨询');
								that.$router.push({
									name: 'home'
								})
							}
							window.sessionStorage.setItem('videoId', '');
						} else if (window.sessionStorage.getItem('repairDetail')) {
							// 直接跳转维修详情
							const repairDetail = JSON.parse(window.sessionStorage.getItem('repairDetail')) || {};
							const { id, menuAction } = repairDetail;
							// 映射关系,menuAction:对应权限
							const permMap = {
								repairRequest: 'home:repair:apply',
								repairApproval: 'home:repair:approval',
								logisticsApproval: 'home:repair:logisticsApproval',
								claimantLogistics: 'home:repair:claimsLogistics',
								expenseApproval: 'home:repair:expenseApproval',
								followUp: 'home:repair:returnVisit'
							};

							const perm = permMap[menuAction] || '';

							if (r.data?.userDetail?.perms?.includes(perm)) {
								that.$router.push({
									name: 'repairRequestDetail',
									query: { id, menuAction }
								});
							} else {
								that.$message.warning('当前用户没有权限');
								that.$router.push({ name: 'home' });
							}

							// 清除 sessionStorage
							window.sessionStorage.removeItem('repairDetail');
						} else {
							that.$router.push({
								name: 'home'
							})
						}

					} else {
						that.$message.error(r.msg);
					}
				}
			);
		}, 1000),
		// 注册
		handleRegister() {
			this.$router.push({
				name: 'register'
			})
		},
		// 阅读免责
		handleCheck() {
			this.visible = true;
		},
		// 不同意免责
		handleNoAgree() {
			this.visible = false;
			this.checked = false;
		},
		// 同意免责
		submit() {
			this.checked = true;
			this.visible = false;
			this.handleLogin();
		},
		// 找回密码
		handleForget() {
			this.$router.push({
				name: 'forget'
			})
		},
		handleForgetSubmit: debounce(function () {
			if (this.forget == '') {
				this.$message.error('请输入邮箱号');
				return
			}
			let that = this;
			that.$request.post(
				"forgetPass",
				false,
				{
					code: that.forget,
				},
				function (r) {
					that.forgetVisible = false;
					if (r.code == "0") {
						that.$message('请登录邮箱按要求修改密码');
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		}, 1000)
	}
}
</script>
<style lang="scss" scoped>
.check-span {
	color: rgba(17, 127, 252, 1);
	font-size: 13px;
	margin-left: 5px;
	cursor: pointer;
}

.lh-17 {
	line-height: 17px;
}

.login-content {
	position: absolute;
	width: 100%;
	height: 100%;
	background: url('~@/assets/images/beijing2.png') no-repeat;
	background-color: #ECF7FF;
	background-size: contain;
	background-position: center;

	.welcome-title {
		position: absolute;
		right: 13%;
		top: 5%;
		font-size: 22px;
		font-weight: 500;
		color: #117FFC;
	}

	.login-box {
		display: flex;
		position: absolute;
		// top: 14%;
		top: 10%;
		left: 18%;
		width: 62%;
		// height: 70%;
		height: 80%;
		border-radius: 20px;
		background-color: #fff;

		.login-box-left {
			flex: 3;

			.login-logo {
				display: block;
				width: 35%;
				margin: 7% auto;
			}

			.login-ul {
				width: 70%;
				margin: 0 auto;

				li {
					position: relative;
					list-style: none;
					margin-bottom: 26px;

					.left-icon {
						position: absolute;
						width: 20px;
						top: 7px;
						left: 6%;
					}

					.right-icon {
						position: absolute;
						width: 20px;
						top: 14px;
						right: 3%;
					}

					input {
						width: 100%;
						height: 36px;
						box-sizing: border-box;
						padding-left: 15%;
						padding-right: 10%;
						font-size: 14px;
						outline: none;
						border: 1px solid #ddd;
						border-radius: 5px;
					}

					.forget-btn {
						float: left;
						text-decoration: underline;
						color: #409EFF;
						font-size: 14px;
						cursor: pointer;
					}

					.rigister-btn {
						float: right;
						text-decoration: underline;
						color: #409EFF;
						font-size: 14px;
						cursor: pointer;
					}

					.el-checkbox {
						color: #409EFF;
					}
				}

				.submit-btn {
					display: block;
					outline: none;
					border: none;
					width: 100%;
					height: 36px;
					background: #117FFC;
					box-shadow: 0px 5px 15px 0px rgba(17, 127, 252, 50%);
					border-radius: 8px;
					color: #fff;
					text-align: center;
					line-height: 36px;
					font-weight: 500;
					cursor: pointer;
					margin-bottom: 26px;
				}

				.enter-btn {
					text-align: center;
					color: #117FFC;
					font-weight: 500;
					clear: both;

					.point-icon {
						width: 20px;
						vertical-align: middle;
						margin-right: 5px;
					}

					a {
						color: #117FFC;
					}
				}
			}

			.qr-box {
				display: flex;
				justify-content: center;
				align-items: baseline;
				padding: 10px 15px;

				.qr-item {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					margin: 0 15px;

					img {
						width: 100px;
						height: 100px;
					}

					p {
						font-size: 14px;
					}
				}
			}
		}

		.login-box-right {
			flex: 2;
		}
	}
}

.banquan {
	text-align: center;
	color: #A8A8A8;
	position: absolute;
	bottom: 40px;
	left: 50%;
	margin-left: -159px;
}

.beian {
	text-align: center;
	color: #0067FF;
	position: absolute;
	bottom: 16px;
	left: 50%;
	margin-left: -114px;
}

.dialog-title {
	display: block;
	text-align: center;

	span {
		color: #222;
		font-weight: 600;
		font-size: 20px;
	}

	img {
		width: 28px;
		float: right;
		cursor: pointer;
	}
}

.dialog-box {
	color: rgba(68, 68, 68, 1);
	line-height: 36px;
	font-size: 13px;
	box-sizing: border-box;
	padding: 0 30px;
}

.dialog-footer {
	display: block;
	text-align: center;

	.left-btn {
		background-color: rgba(17, 127, 252, 0.2);
		border: none;
		color: rgba(17, 127, 252, 1);
		font-size: 15px;
	}

	.right-btn {
		background-color: rgba(17, 127, 252, 1);
		border: none;
		color: #fff;
		font-size: 15px;
	}
}

.forget-input {
	display: block;
	width: 100%;
	height: 36px;
	box-sizing: border-box;
	padding-left: 10px;
	font-size: 14px;
	outline: none;
	border: 1px solid #ddd;
	border-radius: 5px;
	margin-top: 5px;
}

.forget-submit-btn {
	width: 100%;
	background-color: rgba(17, 127, 252, 1);
	border: none;
	color: #fff;
	font-size: 15px;
}
</style>
